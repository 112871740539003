module.exports = {
  defaultTitle: 'Paulo Elienay',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Paulo Elienay',
  url: 'https://pauloelienay.com',
  legalName: 'Elienay Albuquerque',
  defaultDescription: 'Eu sou Elienay e eu sou um desenvolvedor full-stack',
  socialLinks: {
    twitter: 'http://www.twitter.com/elienaycodes',
    github: 'https://github.com/paulo-e',
    linkedin: 'https://www.linkedin.com/in/paulo-elienay-247a19182/',
    instagram: 'https://instagram.com/pauloelienay',
    youtube: 'https://www.youtube.com/channel/UCiXuYrNclcFvk4y-aXMyu9A',
    // google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  // googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    // facebook: 'appId',
    twitter: '@elienaycodes',
  },
  address: {
    city: 'Fortaleza',
    region: 'Ceará',
    country: 'Brasil',
    // zipCode: 'ZipCode',
  },
  contact: {
    email: 'paulo@pauloelienay.com',
    // phone: 'phone number',
  },
  foundingDate: '2021',
  recaptcha_key: '6LchkFscAAAAAEo3Yxq6AmVbJWug_C2XawK_9egn',
};
